<template>
  <v-container fluid fill-height>
    <v-row style="align-items: center" class="pb-3">
      <v-col align="center">
        <v-card
          elevation="0"
          style="background-color: #E9EFF5"
          class="rounded-circle text-center d-flex flex-column align-center justify-center"
          width="300"
          height="300"
          rounded
        >
          <v-icon color="#A3B1C7" class="ml-15" size="80">mdi-pencil</v-icon>
          <v-icon color="#A3B1C7" class="mr-15" size="100"
            >mdi-vector-polygon</v-icon
          >
        </v-card>
        <p class="mt-5 text-h5">{{ $t("baseline.almostThere") }}</p>
        <p class="label-color pl-1">
          {{ $t("emptyState.noVSInfo") }}
        </p>
        <v-btn
          color="#039BE5"
          rounded
          class="white--text font-weight-bold button-text"
          :to="{
            name: 'MapView'
          }"
          >{{ $t("emptyState.drawStations") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EmptyStateDownload"
};
</script>

<style scoped>
.label-color {
  color: #173466;
}
.button-text {
  text-transform: unset !important;
}
</style>
