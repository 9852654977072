<template>
  <v-row no-gutters>
    <v-col class="py-0">
      <span class="text-h6" style="color: #173466">
        {{ $t("downloadView.createHeader") }}
      </span>
      <v-tabs grow @change="tabChanged">
        <!-- Time Series Tab -->
        <v-tab
          class="text-body-1 font-weight-medium"
          style="background-color: #f6f7fb"
          >{{ $t("downloadView.tabTimeSeries") }}</v-tab
        >
        <v-tab-item class="pt-8 px-6">
          <time-series
            v-if="virtualStations.length > 0"
            :tabChange="tabChange"
            :resetTimeSeriesSelection="resetTimeSeriesSelection"
            @process-download-for-time-series="updateTimeSeriesPostRequest"
          ></time-series>
          <v-row v-if="virtualStations.length === 0 && !isLoading">
            <empty-state-download></empty-state-download>
          </v-row>
        </v-tab-item>

        <!-- Raster Data Tab -->
        <v-tab
          class="text-body-1 font-weight-medium"
          style="background-color: #f6f7fb"
          >{{ $t("downloadView.tabRaster") }}</v-tab
        >
        <v-tab-item class="pt-8 px-6">
          <!-- Raster Data date selector + Filter Products Icon -->
          <date-selector
            :resetRasterDataDate="resetRasterDataDate"
            :filterCount="filterCount"
            @raster-data-start-date-changed="updateRasterDataStartDate"
            @raster-data-end-date-changed="updateRasterDataEndDate"
            @openProductFilter="toogleProductFilter"
          ></date-selector>
          <!-- Filter Raster Data by Product -->
          <product-filter
            @products-filtered="updateRasterDataTable"
            @filtered-count="updateFilterCount"
            @openProductFilter="toogleProductFilter"
            :openFilter="openProductFilter"
            :resetFiltration="resetFiltration"
          >
          </product-filter>
          <!-- Raster Data  -->
          <raster-data
            :rasterDataTableContent="rasterDataTableContent"
            :rasterDataStartDate="rasterDataStartDate"
            :rasterDataEndDate="rasterDataEndDate"
            :resetRasterDataSelection="resetRasterDataSelection"
            :tabChange="tabChange"
            @process-download-for-raster-data="updateRasterDataPostRequest"
          >
          </raster-data>
        </v-tab-item>
      </v-tabs>
      <!-- Process for download button  -->
      <process-for-download
        :payloadForRasterDataPostRequest="payloadForRasterDataPostRequest"
        :payloadForTimeSeriesPostRequest="payloadForTimeSeriesPostRequest"
        @download-raster-data-posted="resetSelectedRasterDataRows"
        @download-time-series-posted="resetSelectionInTimeSeries"
        @openProductFilter="toogleProductFilter"
      >
      </process-for-download>
    </v-col>
  </v-row>
</template>

<script>
import DateSelector from "./RasterData/DateSelector";
import RasterData from "./RasterData/RasterData";
import ProductFilter from "./RasterData/ProductFilter";
import ProcessForDownload from "./ProcessForDownload";
import TimeSeries from "./TimeSeries/TimeSeries";
import { mapActions, mapState } from "vuex";
import EmptyStateDownload from "@/core/components/EmptyStates/EmptyStateDownload";

export default {
  components: {
    EmptyStateDownload,
    DateSelector,
    ProductFilter,
    RasterData,
    TimeSeries,
    ProcessForDownload
  },
  data() {
    return {
      rasterDataStartDate: null,
      rasterDataEndDate: null,
      resetRasterDataDate: false,
      resetRasterDataSelection: false,
      resetTimeSeriesSelection: false,
      rasterDataTableContent: [],
      filterCount: 0,
      tabChange: false,
      payloadForRasterDataPostRequest: [],
      payloadForTimeSeriesPostRequest: {},
      openProductFilter: false,
      resetFiltration: false
    };
  },
  computed: {
    ...mapState("download", [
      "availableData",
      "selectedRegion",
      "virtualStations",
      "isLoading"
    ])
  },

  methods: {
    ...mapActions("download", [
      "updateIsLoading",
      "updateProcess",
      "updateFilter"
    ]),

    tabChanged(activeTab) {
      this.updateProcess(true);
      if (activeTab === 0) {
        this.tabChange = !this.tabChange;
        this.payloadForRasterDataPostRequest = [];
      } else if (activeTab === 1) {
        this.tabChange = !this.tabChange;
        this.payloadForTimeSeriesPostRequest = {};
      }
      this.$emit("tab-changed", activeTab);
    },
    resetSelectedRasterDataRows() {
      this.resetRasterDataSelection = !this.resetRasterDataSelection;
      this.resetRasterDataDate = !this.resetRasterDataDate;
      this.resetFiltration = !this.resetFiltration;
    },
    resetSelectionInTimeSeries() {
      this.resetTimeSeriesSelection = !this.resetTimeSeriesSelection;
    },

    updateRasterDataPostRequest(data) {
      this.payloadForRasterDataPostRequest = data;
    },
    updateTimeSeriesPostRequest(data) {
      this.payloadForTimeSeriesPostRequest = data;
    },
    updateRasterDataTable(filteredProducts) {
      this.rasterDataTableContent = filteredProducts;
    },
    updateRasterDataStartDate(date) {
      this.rasterDataStartDate = date;
    },
    updateRasterDataEndDate(date) {
      this.rasterDataEndDate = date;
    },
    updateFilterCount(count) {
      this.filterCount = count;
    },
    toogleProductFilter(value) {
      this.openProductFilter = value;
    }
  },
  watch: {
    selectedRegion() {
      this.rasterDataStartDate = null;
      this.rasterDataEndDate = null;
      this.resetRasterDataDate = !this.resetRasterDataDate;
      this.resetRasterDataSelection = !this.resetRasterDataSelection;
      this.resetTimeSeriesSelection = !this.resetTimeSeriesSelection;
    },
    availableData() {
      this.rasterDataTableContent = this.availableData;
      this.updateIsLoading(false);
    }
  }
};
</script>
<style scoped></style>
