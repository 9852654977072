<template>
  <v-btn class="px-0" text @click="goBack" style="color: #173466">
    <v-icon size="x-large">mdi-chevron-left</v-icon>
    <span class="text-body-1">{{ $t("downloadView.returToPrevious") }}</span>
  </v-btn>
</template>

<script>
export default {
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
