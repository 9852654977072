<template>
  <v-row
    v-if="openFilter"
    style="background-color: #f6f9f9"
    class="pa-4"
    no-gutters
  >
    <v-col cols="auto" v-for="data in availableData" :key="data.product">
      <v-chip
        class="ma-1 pa-3"
        elevated
        :text-color="selectedProductsFilter[data.product] ? '#039BE5' : 'black'"
        :key="data.product"
        @click="toggleChipSelection(data.product)"
        :outlined="selectedProductsFilter[data.product]"
      >
        {{ data.title }}
        <template v-if="selectedProductsFilter[data.product]">
          <v-icon right>
            mdi-check
          </v-icon>
        </template>
      </v-chip>
    </v-col>
    <v-col class="text-right">
      <v-btn text rounded @click="resetFilterSettings">
        <v-icon>mdi-filter-off-outline</v-icon>
        {{ $t("downloadView.resetFilter") }}
      </v-btn>
      <v-btn text rounded @click="closeFilter">
        <v-icon>mdi-close</v-icon>
        {{ $t("downloadView.closeFilter") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    openFilter: Boolean,
    resetFiltration: Boolean
  },
  data() {
    return {
      showFilterRow: false,
      selectedProductsFilter: {},
      filteredProducts: []
    };
  },
  computed: {
    ...mapState("download", ["availableData", "filter"])
  },
  methods: {
    ...mapActions("download", ["updateFilter"]),

    toggleChipSelection(product) {
      if (this.selectedProductsFilter[product]) {
        this.$delete(this.selectedProductsFilter, product);
      } else {
        this.$set(this.selectedProductsFilter, product, true);
      }
    },
    resetFilterSettings() {
      this.filteredProducts = this.availableData;
      this.selectedProductsFilter = {};
      this.updateFilter(!this.filter);
      this.$emit("openProductFilter", this.filter);
    },
    closeFilter() {
      this.updateFilter(!this.filter);
      this.$emit("openProductFilter", this.filter);
    }
  },
  watch: {
    selectedProductsFilter() {
      if (Object.keys(this.selectedProductsFilter).length > 0) {
        this.filteredProducts = this.availableData.filter(
          data => this.selectedProductsFilter[data.product]
        );
        this.$emit("filtered-count", this.filteredProducts.length);
      } else {
        this.filteredProducts = [...this.availableData];
        this.$emit("filtered-count", 0);
      }
      this.$emit("products-filtered", this.filteredProducts);
    },
    resetFiltration() {
      this.filteredProducts = this.availableData;
      this.selectedProductsFilter = {};
    }
  }
};
</script>
