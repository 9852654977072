<template>
  <v-row class="text-right" no no-gutters>
    <v-col class="px-0 py-6">
      <v-btn
        class="mr-4"
        rounded
        :color="'#536380'"
        @click="removeSelectedResultsRows"
        :disabled="
          (rasterDataToRemove.length === 0 &&
            timeSeriesToRemove.length === 0) ||
            isRemoveDisabled
        "
      >
        {{ $t("downloadView.removeBtn") }}
      </v-btn>
      <v-btn
        rounded
        :color="'#039be5'"
        @click="triggerDownload"
        :disabled="
          (!rasterDataToDownload.length && !timeSeriesToDownload.length) ||
            isRemoveDisabled
        "
      >
        {{ $t("downloadView.downloadBtn") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  props: {
    rasterDataToRemove: Array,
    rasterDataToDownload: Array,
    timeSeriesToRemove: Array,
    timeSeriesToDownload: Array
  },
  data() {
    return {
      isRemoveDisabled: false
    };
  },
  computed: {
    ...mapState("download", {
      downloads: "downloads"
    }),
    ...mapGetters("management", ["activeRegion"])
  },

  methods: {
    ...mapActions("download", ["updateDownloads"]),
    ...mapActions("app", ["showSnackbar"]),

    removeSelectedResultsRows() {
      this.isRemoveDisabled = true;

      const rasterDataIdsToRemove = this.rasterDataToRemove.map(
        panel => panel.id
      );
      const rasterDataDeletePromises = this.rasterDataToRemove.map(panelId =>
        axios.delete(
          `/userdata/${this.activeRegion.id}/raster-download/${panelId.id}`
        )
      );

      const timeSeriesIdsToRemove = this.timeSeriesToRemove.map(
        series => series.id
      );
      const timeSeriesDeletePromises = this.timeSeriesToRemove.map(seriesId =>
        axios.delete(
          `/userdata/${this.activeRegion.id}/timeseries-download/${seriesId.id}`
        )
      );

      const allDeletePromises = [
        ...rasterDataDeletePromises,
        ...timeSeriesDeletePromises
      ];

      Promise.allSettled(allDeletePromises).then(results => {
        const errors = results.filter(result => result.status === "rejected");

        errors.forEach(error => {
          this.showSnackbar({
            show: true,
            message: this.$t("downloadView.removeError") + `${error.reason}`,
            color: "error"
          });
        });

        this.downloadEntries = this.downloads.filter(
          download =>
            !rasterDataIdsToRemove.includes(download.id) &&
            !timeSeriesIdsToRemove.includes(download.id)
        );
        this.updateDownloads(this.downloadEntries);

        this.$emit("items-removed");
        this.isRemoveDisabled = false;
      });
    },
    triggerDownload() {
      this.rasterDataToDownload.forEach((file, index) => {
        setTimeout(() => {
          window.open(file.urls);
        }, (index + 1) * 500);
      });
      this.timeSeriesToDownload.forEach((file, index) => {
        setTimeout(() => {
          window.open(file.urls);
        }, (index + 1) * 500);
      });
      this.$emit("items-downloaded");
    }
  }
};
</script>

<style scoped></style>
