import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";

let downloadMixin = {
  methods: {
    ...mapActions("download", ["updateVirtualStations", "updateDownloads"]),

    async fetchVirtualStation(activeRegion) {
      const response = await axios.get(
        `/userdata/virtual-stations/?region=${activeRegion}`
      );
      if (response.data.length) {
        this.updateVirtualStations(response.data);
      } else {
        this.updateVirtualStations([]);
      }
    },
    async getDownloads(regionId) {
      const response = await axios.get(
        `/userdata/${regionId}/raster-download/`
      );
      const responseTS = await axios.get(
        `/userdata/${regionId}/timeseries-download/`
      );

      const usersDownloads = response.data.map(item => {
        let dateTimeValue;
        if (Array.isArray(item.timesteps) && item.timesteps.length > 1) {
          const firstDateTime = item.timesteps[0];
          const lastDateTime = item.timesteps[item.timesteps.length - 1];

          dateTimeValue = `${firstDateTime.split("T")[0]}, ${
            firstDateTime.split("T")[1]
          }\u00A0\u00A0to\u00A0\u00A0${lastDateTime.split("T")[0]}, ${
            lastDateTime.split("T")[1]
          }`;
        } else if (
          Array.isArray(item.timesteps) &&
          item.timesteps.length === 1
        ) {
          dateTimeValue = `${item.timesteps[0].split("T")[0]}, ${
            item.timesteps[0].split("T")[1]
          }`;
        } else {
          dateTimeValue = "-";
        }

        return {
          createdAt: item.created_at,
          dataType: "Raster data",
          productName: item.product.toUpperCase(),
          dateTime: dateTimeValue,
          fileType: "-",
          status: item.status,
          id: item.id,
          filesData: []
        };
      });

      const layersLookup = Object.assign({}, ...this.layersCollection);
      const userTSDownloads = responseTS.data.map(item => {
        const productNames = item.rastless_layers.map(layerId =>
          layersLookup[layerId]
            ? layersLookup[layerId].product.toUpperCase()
            : null
        );

        const productName = productNames.join(",\u00A0\u00A0");

        const fileType = item.multiple_files
          ? "Multiple Files"
          : "All-in-one-file";

        return {
          createdAt: item.created_at,
          dataType: "Time Series data",
          productName: productName,
          dateTime: `${item.start_date}\u00A0\u00A0to\u00A0\u00A0${item.end_date}`,
          fileType: fileType,
          status: item.status,
          id: item.id,
          filesData: item.zip_file
        };
      });
      const combinedDownloads = [...usersDownloads, ...userTSDownloads];
      this.updateDownloads(combinedDownloads);
    },
    async fetchInProgressItems() {
      this.resultChangedStatus = [];
      const inProgressItems = this.downloads.filter(
        item => item.status === "In progress"
      );

      const promises = inProgressItems.map(async item => {
        try {
          let dataType;

          if (item.dataType === "Time Series data") {
            dataType = "timeseries-download";
          } else if (item.dataType === "Raster data") {
            dataType = "raster-download";
          }
          const response = await axios.get(
            `/userdata/${this.activeRegion.id}/${dataType}/${item.id}`
          );

          return response.data;
        } catch (error) {
          this.showSnackbar({
            show: true,
            message: this.$t("downloadView.fetchingSingleId") + `${error}`,
            color: "error"
          });
          return null;
        }
      });

      const results = await Promise.allSettled(promises);

      results.forEach(result => {
        if (result.status === "fulfilled") {
          if (result.value.status === "Ready") {
            this.resultChangedStatus.push(result.value.id);
          }
        } else {
          this.showSnackbar({
            show: true,
            message:
              this.$t("downloadView.failedPromiseError") + `${result.reason}`,
            color: "error"
          });
        }
      });
    }
  },
  computed: {
    ...mapState("download", ["layersCollection", "downloads"]),
    ...mapGetters("management", ["activeRegion"])
  }
};

export default downloadMixin;
