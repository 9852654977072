<template>
  <v-row no-gutters>
    <v-col class="py-0">
      <v-row class="pb-4" no-gutters>
        <!-- Results Header -->
        <v-col
          cols="10"
          class="py-0  d-flex align-center"
          style="font-size: 20px"
        >
          <span class="text-h6 pr-6" style="color: #173466">
            {{ $t("downloadView.resultsHeader") }}
          </span>
          <span class="text-caption" style="color: #11274D">
            {{ $t("downloadView.resultsHeaderInfo") }}
          </span>
        </v-col>

        <!-- Refresh Button -->
        <v-col cols="2" class="d-flex justify-end pa-0 ">
          <v-btn color="secondary" rounded @click="reloadResults">
            <v-icon left>
              mdi-reload
            </v-icon>
            {{ $t("downloadView.reloadResult") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear
        v-if="refresh"
        indeterminate
        color="primary"
        class="mt-4"
      ></v-progress-linear>
      <!-- Processed Data Results rows -->
      <results-rows
        :resetResultsRows="resetResultsRows"
        :resultChangedStatus="resultChangedStatus"
        @selected-raster-data-rows-to-remove="updateRemove"
        @selected-raster-data-rows-to-download="updateDownloadBtn"
        @selected-time-series-rows-to-remove="updateTSRemove"
        @selected-time-series-rows-to-download="updateTSDownloadBtn"
      ></results-rows>
      <!-- Remove and Download buttons -->
      <results-actions
        :rasterDataToRemove="rasterDataToRemove"
        :rasterDataToDownload="rasterDataToDownload"
        :timeSeriesToRemove="timeSeriesToRemove"
        :timeSeriesToDownload="timeSeriesToDownload"
        @items-downloaded="resetRowsToDownload"
        @items-removed="resetRowsToRemove"
      ></results-actions>
    </v-col>
  </v-row>
</template>
<script>
import ResultsRows from "./ProcessedDataResultsSubcomponents/ResultsRows";
import ResultsActions from "./ProcessedDataResultsSubcomponents/ResultsActions";
import { mapActions, mapState } from "vuex";
import downloadMixin from "@/core/mixins/download.mixin";

export default {
  components: {
    ResultsRows,
    ResultsActions
  },
  mixins: [downloadMixin],
  props: {
    regionChanged: Boolean
  },

  data() {
    return {
      resetResultsRows: false,
      rasterDataToRemove: [],
      rasterDataToDownload: [],
      timeSeriesToRemove: [],
      timeSeriesToDownload: [],
      resultChangedStatus: []
    };
  },
  computed: {
    ...mapState("download", ["refresh", "downloads"])
  },

  methods: {
    ...mapActions("download", ["updateRefresh"]),
    ...mapActions("app", ["showSnackbar"]),
    resetRowsToRemove() {
      this.rasterDataToRemove = [];
      this.timeSeriesToRemove = [];

      this.resetResultsRows = !this.resetResultsRows;
    },
    resetRowsToDownload() {
      this.rasterDataToDownload = [];
      this.timeSeriesToDownload = [];
      this.resetResultsRows = !this.resetResultsRows;
    },
    async reloadResults() {
      await this.fetchInProgressItems();
      this.resetResultsRows = !this.resetResultsRows;
    },
    updateRemove(RDrowsSelectedToRemove) {
      this.rasterDataToRemove = RDrowsSelectedToRemove;
    },
    updateDownloadBtn(RDrowsSelectedToDownload) {
      this.rasterDataToDownload = RDrowsSelectedToDownload;
    },
    updateTSRemove(TSrowsSelectedToRemove) {
      this.timeSeriesToRemove = TSrowsSelectedToRemove;
    },
    updateTSDownloadBtn(TSrowsSelectedToDownload) {
      this.timeSeriesToDownload = TSrowsSelectedToDownload;
    }
  },
  watch: {
    regionChanged() {
      this.resetResultsRows = !this.resetResultsRows;
    }
  }
};
</script>
