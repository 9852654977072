<template>
  <v-row class="pl-6">
    <!-- Start Date  -->
    <v-col cols="2">
      <v-menu
        v-model="startDateCalenderVisible"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-bind="attrs" v-on="on" v-model="startDate" clearable>
            <template v-slot:label>
              <span style="color: #039BE5">{{
                $t("downloadView.startDate")
              }}</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker @input="handleStartDateSelect"></v-date-picker>
      </v-menu>
    </v-col>
    <!-- End Date  -->
    <v-col cols="2">
      <v-menu v-model="endDateCalenderVisible" :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-bind="attrs" v-on="on" v-model="endDate" clearable>
            <template v-slot:label>
              <span style="color: #039BE5">{{
                $t("downloadView.endDate")
              }}</span>
            </template>
          </v-text-field>
        </template>
        <v-date-picker @input="handleEndDateSelect"></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="5" class="px-0"> </v-col>
    <v-col cols="3" class="px-6 d-flex align-center justify-end">
      <v-btn text style="color: #039BE5" @click="toggleFilterRow">
        <v-icon>mdi-filter</v-icon>
        {{ $t("downloadView.filterProducts") + " (" + filterCount + ")" }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  props: {
    resetRasterDataDate: Boolean,
    filterCount: Number
  },
  data() {
    return {
      startDateCalenderVisible: false,
      endDateCalenderVisible: false,
      startDate: null,
      endDate: null
    };
  },
  computed: {
    ...mapState("download", ["filter"])
  },
  methods: {
    ...mapActions("download", ["updateFilter"]),

    toggleFilterRow() {
      this.updateFilter(!this.filter);
      this.$emit("openProductFilter", this.filter);
    },
    handleStartDateSelect(date) {
      this.startDate = date;
      this.startDateCalenderVisible = false;
    },
    handleEndDateSelect(date) {
      this.endDate = date;
      this.endDateCalenderVisible = false;
    }
  },
  watch: {
    startDate(newValue) {
      this.$emit("raster-data-start-date-changed", newValue);
    },
    endDate(newValue) {
      this.$emit("raster-data-end-date-changed", newValue);
    },
    resetRasterDataDate() {
      this.startDate = null;
      this.endDate = null;
    }
  }
};
</script>
