<template>
  <v-row>
    <v-col class=" pa-0">
      <v-data-table
        fixed-header
        :height="tableHeight"
        :headers="tableHeaders"
        :items="downloads"
        :items-per-page="-1"
        :sort-by="'createdAt'"
        :sort-desc="true"
        hide-default-footer
        item-key="id"
        class="elevation-0 resultsTable custom-scrollbar"
        show-select
        v-model="selectedResultsRows"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ formatCreatedAt(item.createdAt) }}
        </template>
        <template v-slot:item.status="{ item }">
          <span class="pr-5" v-if="item.status === 'Ready'">
            <v-icon color="#34944F">mdi-check-circle-outline</v-icon>
          </span>
          <span class="pr-5" v-else-if="item.status === 'In progress'">
            <v-icon color="#D9A609">mdi-loading mdi-spin</v-icon>
          </span>
          <span class="pr-5" v-else>
            <v-icon color="red">mdi-close-circle-outline</v-icon>
          </span>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    resetResultsRows: Boolean,
    resultChangedStatus: Array
  },
  data() {
    return {
      fetchedResultsRows: [],
      selectedResultsRows: [],
      rasterDataUrls: [],
      results: [],
      manualSelectionChange: true,
      timeSeriesUrls: []
    };
  },
  computed: {
    ...mapState("download", ["downloads"]),
    ...mapGetters("management", ["activeRegion"]),
    tableHeight() {
      const rowsHeight = this.downloads.length * 60;
      if (rowsHeight === 0) {
        return "90px";
      }

      const maxTableHeight = 276;
      if (rowsHeight > maxTableHeight) {
        return maxTableHeight;
      } else {
        return `${rowsHeight + 36}px`;
      }
    },
    tableHeaders() {
      return [
        {
          text: this.$t("downloadView.createdAt"),
          value: "createdAt",
          align: "left"
        },
        {
          text: this.$t("downloadView.dataType"),
          value: "dataType",
          align: "left"
        },
        {
          text: this.$t("downloadView.productName"),
          value: "productName",
          align: "left"
        },
        {
          text: this.$t("downloadView.timestepRange"),
          value: "dateTime",
          align: "left"
        },
        {
          text: this.$t("downloadView.fileType"),
          value: "fileType",
          align: "left"
        },
        {
          text: this.$t("downloadView.status"),
          value: "status",
          align: "right"
        }
      ];
    }
  },
  methods: {
    ...mapActions("download", ["fetchDownloadData", "updateDownloadStatus"]),
    ...mapActions("app", ["showSnackbar"]),

    formatCreatedAt(createdAt) {
      const date = new Date(createdAt);
      const timezoneOffset = date.getTimezoneOffset();
      date.setMinutes(date.getMinutes() - timezoneOffset);
      const formatedDate = moment(date);
      return formatedDate.format("YYYY-MM-DD, HH:mm:ss");
    },
    async fetchDownloadDetails(id, dataType) {
      if (this.fetchedResultsRows.includes(id)) {
        this.removeDownloadDetails(id, dataType);
        return;
      }

      try {
        const result = await this.fetchDownloadData({
          id,
          dataType,
          region: this.activeRegion.id
        });

        if (result) {
          this.updateDownloadUrls(id, dataType, result);
          this.fetchedResultsRows.push(id);
        }
      } catch (error) {
        this.handleError(error);
      }
    },
    removeDownloadDetails(id, dataType) {
      this.fetchedResultsRows = this.fetchedResultsRows.filter(
        panelId => panelId !== id
      );

      if (dataType === "Raster data") {
        this.rasterDataUrls = this.rasterDataUrls.filter(
          file => file.id !== id
        );
      } else if (dataType === "Time Series data") {
        this.timeSeriesUrls = this.timeSeriesUrls.filter(
          file => file.id !== id
        );
      }
    },

    updateDownloadUrls(id, dataType, result) {
      const urlArray = result.map(file => file.url);
      if (dataType === "Raster data") {
        this.rasterDataUrls.push({ id, urls: urlArray[0] });
      } else if (dataType === "Time Series data") {
        this.timeSeriesUrls.push({ id, urls: urlArray[0] });
      }
    },

    handleError(error) {
      this.showSnackbar({
        show: true,
        message:
          this.$t("downloadView.fetchDownloadErrorMsg") + `${error.message}`,
        color: "error"
      });
    }
  },
  watch: {
    resultChangedStatus() {
      const newStatus = "Ready";

      this.resultChangedStatus.forEach(resultChangedId => {
        const parsedId = parseInt(resultChangedId);
        this.updateDownloadStatus({ id: parsedId, status: newStatus });
      });
    },
    resetResultsRows() {
      this.manualSelectionChange = false;
      this.fetchedResultsRows = [];
      this.selectedResultsRows = [];
      this.rasterDataUrls = [];
      this.results = [];
      this.timeSeriesUrls = [];
    },
    rasterDataUrls() {
      this.$emit("selected-raster-data-rows-to-download", this.rasterDataUrls);
      this.$emit("selected-raster-data-rows-to-remove", this.rasterDataUrls);
    },
    timeSeriesUrls() {
      this.$emit("selected-time-series-rows-to-download", this.timeSeriesUrls);
      this.$emit("selected-time-series-rows-to-remove", this.timeSeriesUrls);
    },

    selectedResultsRows(newVal, oldVal) {
      if (!this.manualSelectionChange) {
        this.manualSelectionChange = true;
        return;
      }

      const addedItems = newVal.filter(item => !oldVal.includes(item));
      const removedItems = oldVal.filter(item => !newVal.includes(item));

      addedItems.forEach(item => {
        if (
          item.dataType === "Raster data" ||
          item.dataType === "Time Series data"
        ) {
          this.fetchDownloadDetails(item.id, item.dataType);
        }
      });

      removedItems.forEach(item => {
        if (
          item.dataType === "Raster data" ||
          item.dataType === "Time Series data"
        ) {
          this.removeDownloadDetails(item.id, item.dataType);
        }
      });
    }
  }
};
</script>
<style scoped>
>>> .theme--light.v-data-table thead tr:last-child th {
  border-bottom: none;
  box-shadow: none;
  background-color: #f6f7fb;
}

>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

/* TABLE HEADER height */
>>> .theme--light.v-data-table thead tr:last-child th {
  height: 36px;
}

/*
 Hide the select-all checkbox in the header
*/
>>> .v-data-table thead tr th:first-child div {
  visibility: hidden;
}

/*
 Result table row height
*/
>>> .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  height: 60px;
}

.resultsTable {
  background-color: #f6f7fb;
  color: #11274d;
}

>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2) {
  color: #173466;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}
</style>
