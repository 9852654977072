<template>
  <v-row no-gutters>
    <v-col class="py-0">
      <v-progress-linear
        v-if="isLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-data-table
        fixed-header
        :height="tableHeight"
        hide-default-footer
        item-key="date"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="-1"
        class="elevation-0 custom-scrollbar"
        show-select
        v-model="selectedRasterDataRows"
      >
        <template v-slot:item.date="{ item }">
          <span class="date">{{ item.date.split("T")[0] }}</span>
          {{ "\u00A0\u00A0\u00A0\u00A0" }}

          <span class="time">{{ item.date.split("T")[1] }}</span>
        </template>

        <template
          v-for="(header, index) in tableHeaders.slice(1)"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <div :key="'icon-wrapper-' + index" class="text-center">
            <v-icon
              v-if="item[header.value] === 'check'"
              :key="'check-' + index"
              color="#34944F"
              >mdi-check-circle-outline
            </v-icon>

            <v-icon v-else :key="'circle' + index" color="#D8E1EB"
              >mdi-circle-outline
            </v-icon>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    rasterDataStartDate: String,
    rasterDataEndDate: String,
    resetRasterDataSelection: Boolean,
    rasterDataTableContent: Array,
    tabChange: Boolean
  },
  data() {
    return {
      selectedRasterDataRows: [],
      payloadRasterDataPostRequest: [],
      tableContent: this.rasterDataTableContent,
      firstSceneDateTime: null,
      lastSceneDateTime: null
    };
  },
  computed: {
    ...mapState("download", ["availableData", "isLoading"]),

    tableData() {
      const dates = new Set();
      const productData = {};
      this.tableContent.forEach(item => {
        item.availableScenes.forEach(scene => {
          dates.add(scene.datetime);
          if (!productData[item.product]) {
            productData[item.product] = {
              dates: [],
              sensors: [],
              layerId: item.layerId
            };
          }
          productData[item.product].dates.push(scene.datetime);
          productData[item.product].sensors.push(scene.sensor);
        });
      });
      let sortedDates = Array.from(dates).sort(
        (a, b) => new Date(b) - new Date(a)
      );

      if (this.rasterDataStartDate) {
        sortedDates = sortedDates.filter(
          date => new Date(date) >= new Date(this.rasterDataStartDate)
        );
      }
      if (this.rasterDataEndDate) {
        const adjustedEndDate = new Date(this.rasterDataEndDate);
        adjustedEndDate.setHours(23, 59, 59, 999);
        sortedDates = sortedDates.filter(
          date => new Date(date) <= adjustedEndDate
        );
      }

      const rows = sortedDates.map(date => {
        const row = { date };
        for (let product in productData) {
          if (product == "rgb") {
            let index = -1;
            for (let i = 0; i < productData[product].dates.length; i++) {
              if (productData[product].dates[i] === date) {
                if (
                    productData[product].sensors[i] !== "SKYST" &&
                    productData[product].sensors[i] !== "SUDOV"
                ) {
                  index = i;
                  break;
                }
              }
            }

            if (index !== -1) {
              row[product] = "check";
            } else {
              row[product] = "circle";
            }
          } else {
            row[product] = productData[product].dates.includes(date)
                ? "check"
                : "circle";
          }

          row[`${product}_layerId`] = productData[product].layerId;
        }
        return row;
      });
      return rows;
    },
    tableHeaders() {
      return [
        { text: this.$t("downloadView.date"), value: "date" },
        ...this.tableContent.map(item => ({
          text: item.title,
          value: item.product
        }))
      ];
    },
    tableHeight() {
      const rowsHeight = this.tableData.length * 60;
      if (rowsHeight === 0) {
        return "90px";
      }
      const maxTableHeight = 396;
      if (rowsHeight > maxTableHeight) {
        return maxTableHeight;
      } else {
        return `${rowsHeight + 36}px`;
      }
    }
  },
  methods: {
    ...mapActions("download", [
      "updateIsLoading",
      "updateFirstDate",
      "updateLastDate",
      "updateProcess"
    ]),

    updatePayloadForRasterDataPostRequest() {
      const productMapping = {};

      this.tableData.forEach(row => {
        for (let key in row) {
          if (key.includes("_layerId")) {
            const productKey = key.replace("_layerId", "");
            if (row[productKey] === "check") {
              if (!productMapping[productKey]) {
                productMapping[productKey] = {
                  rastless_layer_id: row[key],
                  timesteps: [],
                  product: productKey
                };
              }
              productMapping[productKey].timesteps.push(row.date);
            }
          }
        }
      });
      if (
        this.selectedRasterDataRows &&
        this.selectedRasterDataRows.length > 0
      ) {
        const selectedDates = this.selectedRasterDataRows.map(
          entry => entry.date
        );
        for (let productKey in productMapping) {
          productMapping[productKey].timesteps = productMapping[
            productKey
          ].timesteps.filter(date => selectedDates.includes(date));
          if (productMapping[productKey].timesteps.length === 0) {
            delete productMapping[productKey];
          }
        }
      }

      this.payloadRasterDataPostRequest = Object.values(productMapping);
    }
  },
  watch: {
    tabChange() {
      this.selectedRasterDataRows = [];
    },
    rasterDataTableContent(val) {
      this.tableContent = val;
      this.updatePayloadForRasterDataPostRequest();
      this.updateIsLoading(false);
    },
    availableData() {
      this.tableContent = this.availableData;
      if (this.tableContent.length > 0) {
        this.updateIsLoading(false);
      }
    },

    rasterDataStartDate() {
      this.updatePayloadForRasterDataPostRequest();
    },
    rasterDataEndDate() {
      this.updatePayloadForRasterDataPostRequest();
    },
    payloadRasterDataPostRequest(newVal) {
      if (this.selectedRasterDataRows.length > 0 && newVal.length > 0) {
        this.updateProcess(false);
        this.$emit("process-download-for-raster-data", newVal);
      } else {
        this.updateProcess(true);
      }
    },
    selectedRasterDataRows(newVal) {
      this.updatePayloadForRasterDataPostRequest();

      if (newVal.length > 0 && this.payloadRasterDataPostRequest.length > 0) {
        this.updateProcess(false);
        this.$emit(
          "process-download-for-raster-data",
          this.payloadRasterDataPostRequest
        );
      } else {
        this.updateProcess(true);
      }
    },
    resetRasterDataSelection() {
      this.selectedRasterDataRows = [];
    },
    tableData(newVal) {
      if (newVal && newVal.length) {
        this.updateLastDate(newVal[0].date.split("T")[0]);
        this.updateFirstDate(newVal[newVal.length - 1].date.split("T")[0]);
      } else {
        this.updateLastDate(null);
        this.updateFirstDate(null);
      }
    }
  }
};
</script>
<style scoped>
.custom-scrollbar ::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}

/* TABLE HEADER height & bg color*/
>>> .theme--light.v-data-table thead tr th {
  background-color: white !important;
  height: 36px;
}

>>> .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr {
  height: 60px;
}

>>> .theme--light.v-data-table thead tr:last-child th {
  border-bottom: none;
  box-shadow: none;
  background-color: #f6f7fb;
}

>>> .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}

.date {
  color: #173466;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.time {
  color: #173466;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.v-data-table >>> th:not(:nth-child(2)) {
  text-align: center !important;
}
</style>
