<template>
  <v-row class="ma-0 ga-3">
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <!-- Virtual Stations -->
    <v-col cols="4" class="pa-0">
      <v-card elevation="0" class="pa-6 ts-border fill-height">
        <span class="text-h6 label-color">
          {{ $t("downloadView.tsStations") }}
        </span>
        <v-text-field
          :label="$t('downloadView.searchForAoi')"
          prepend-inner-icon="mdi-magnify"
          v-model="searchQuery"
          style="background-color: #E9EFF5"
          filled
          dense
          hide-details
          rounded
          clearable
          class="mt-6"
        ></v-text-field>
        <!-- Checkbox all virtual stations -->
        <v-row>
          <v-col>
            <v-checkbox
              color="info"
              v-model="selectAll"
              @change="toggleAllStations"
              hide-details
              class="pb-2"
            >
              <template v-slot:label>
                <span class="label-color">{{
                  $t("downloadView.tsALlCheckbox")
                }}</span>
              </template>
            </v-checkbox>

            <v-divider></v-divider>
          </v-col>
          <v-col class="d-flex align-center justify-end">
            <v-icon medium @click="toggleSort">
              mdi-sort-alphabetical-variant
            </v-icon>
          </v-col>
        </v-row>
        <!-- Checkbox for each virtual station -->
        <v-row class="custom-scrollbar" style="height:260px; overflow-y:auto;">
          <v-col class="py-0">
            <v-checkbox
              v-for="station in filteredVirtualStations"
              :key="station.id"
              color="info"
              :value="selectedVirtualStations.includes(station.id)"
              @change="updateSelectedVirtualStations(station.id, $event)"
              class="ma-0 py-4"
              hide-details
            >
              <template v-slot:label>
                <span class="label-color">{{ station.name }}</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Products -->
    <v-col cols="4" class="pa-0 px-3">
      <v-card elevation="0" class="pa-6 ts-border fill-height">
        <span class="text-h6 label-color">
          {{ $t("downloadView.tsProducts") }}
        </span>

        <!-- Checkbox - all products -->
        <v-row>
          <v-col>
            <v-checkbox
              color="info"
              v-model="selectAllProducts"
              @change="toggleAllProducts"
              hide-details
              class="pb-2"
            >
              <template v-slot:label>
                <span class="label-color">{{
                  $t("downloadView.tsALlCheckbox")
                }}</span>
              </template>
            </v-checkbox>
            <v-divider></v-divider>
          </v-col>
          <v-col></v-col>
        </v-row>

        <!-- Checkbox for each product -->
        <v-row class="custom-scrollbar" style="height:310px; overflow-y:auto;">
          <v-col class="py-0">
            <v-checkbox
              v-for="data in filteredAvailableData"
              :key="data.layerId"
              color="info"
              :value="selectedProducts.includes(data.layerId)"
              @change="updateSelectedProducts(data.layerId, $event)"
              class="ma-0 py-4"
              hide-details
            >
              <template v-slot:label>
                <span class="label-color">{{
                  data.product.toUpperCase() + " - " + data.title
                }}</span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!-- Time period -->
    <v-col cols="4" class="pa-0 d-flex flex-column">
      <v-card elevation="0" class="pa-6 ts-border flex-grow-1 mb-3">
        <span class="text-h6 label-color">
          {{ $t("downloadView.tsTimePeriod") }}
        </span>
        <v-checkbox
          color="info"
          v-model="selectAllDates"
          @change="toggleAllDates"
          dense
        >
          <template v-slot:label>
            <span class="label-color">{{
              $t("downloadView.tsALlCheckbox")
            }}</span>
          </template>
        </v-checkbox>
        <v-row>
          <!-- Start Date  -->
          <v-col cols="6">
            <v-menu
              v-model="startDateTSCalenderVisible"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="startDateTS"
                  clearable
                  dense
                  hide-details
                >
                  <template v-slot:label>
                    <span style="color: #039BE5">{{
                      $t("downloadView.startDate")
                    }}</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @input="handleStartDateSelect"
                :min="firstSceneDate"
                :max="lastSceneDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- End Date  -->
          <v-col cols="6">
            <v-menu
              v-model="endDateTSCalenderVisible"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="endDateTS"
                  clearable
                  dense
                  hide-details
                  :messages="dateMessage"
                >
                  <template v-slot:label>
                    <span style="color: #039BE5">{{
                      $t("downloadView.endDate")
                    }}</span>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                @input="handleEndDateSelect"
                :min="firstSceneDate"
                :max="lastSceneDate"
                class="pb-1"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0" class="pa-6 ts-border flex-grow-1 mb-3">
        <v-row>
          <!-- RADIO button - File type  -->
          <v-col cols="12">
            <v-row class="text-h6 pr-6 label-color">
              <v-col>
                {{ $t("downloadView.chooseStatistics") }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-checkbox
                :label="$t('downloadView.statistics')"
                @click="toggleAllStats"
                v-model="activateAllStats"
                hide-details
                :indeterminate="
                  activateAllStats &&
                    selectedStats.length !== availableStats.length
                "
                class="pb-2"
              ></v-checkbox>
            </v-row>
            <v-row no-gutters class="pb-5" v-if="activateAllStats">
              <v-col v-for="stat in availableStats" :key="stat">
                <v-checkbox
                  class="checkbox pr-3"
                  :disabled="stat === 'median'"
                  :value="stat"
                  :label="$t(`statistics.${stat}`)"
                  v-model="selectedStats"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-card elevation="0" class="pa-6 ts-border flex-grow-1">
        <v-row>
          <!-- RADIO button - File type  -->
          <v-col cols="12">
            <v-row class="text-h6 pr-6 label-color">
              <v-col>
                {{ $t("downloadView.tsDataFileType") }}
              </v-col>
            </v-row>
            <v-row class="text-caption label-color">
              <v-col>
                {{ $t("downloadView.tsDataFileTypeInfo") }}
              </v-col>
            </v-row>
            <v-radio-group v-model="multipleFiles" row>
              <v-radio :value="false" color="info">
                <template v-slot:label>
                  <span class="label-color">{{
                    $t("downloadView.tsAllInOneFile")
                  }}</span>
                </template>
              </v-radio>
              <v-radio :value="true" color="info">
                <template v-slot:label>
                  <span class="label-color">{{
                    $t("downloadView.tsMultipleFiles")
                  }}</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
import moment from "moment/moment";

export default {
  props: {
    tabChange: Boolean,
    resetTimeSeriesSelection: Boolean
  },
  data() {
    return {
      searchQuery: "",
      sortAscending: true,
      selectAll: false,
      selectedVirtualStations: [],
      selectAllProducts: false,
      selectedProducts: [],
      selectAllDates: false,
      startDateTSCalenderVisible: false,
      endDateTSCalenderVisible: false,
      startDateTS: null,
      endDateTS: null,
      multipleFiles: false,
      payloadTimeSeriesPostRequest: {
        rastless_layers: [],
        virtual_stations: [],
        start_date: null,
        end_date: null,
        multiple_files: false,
        statistics: ["median"]
      },
      dateMessage: null,
      availableStats: [
        "median",
        "mean",
        "std",
        "min",
        "max",
        "p10",
        "p25",
        "p75",
        "p90",
        "valid_pixels",
        "valid_percent"
      ],
      selectedStats: ["median"],
      activateAllStats: false
    };
  },
  computed: {
    ...mapState("download", [
      "isLoading",
      "virtualStations",
      "availableData",
      "firstSceneDate",
      "lastSceneDate"
    ]),
    filteredAvailableData() {
      return this.availableData.filter(data => data.product !== "rgb");
    },
    filteredVirtualStations() {
      let stations = this.virtualStations.filter(station =>
        station.name.includes(this.searchQuery)
      );

      if (this.sortAscending) {
        stations = stations.sort((a, b) =>
          a.name.toUpperCase().localeCompare(b.name.toUpperCase())
        );
      } else {
        stations = stations.sort((a, b) =>
          b.name.toUpperCase().localeCompare(a.name.toUpperCase())
        );
      }
      return stations;
    }
  },
  methods: {
    ...mapActions("download", ["updateProcess"]),
    toggleAllStats() {
      if (this.activateAllStats) {
        this.selectedStats = this.availableStats;
      } else {
        this.selectedStats = ["median"];
      }
    },
    toggleAllStations() {
      if (this.selectAll) {
        this.selectedVirtualStations = this.virtualStations.map(
          station => station.id
        );
      } else {
        this.selectedVirtualStations = [];
      }
    },
    updateSelectedVirtualStations(stationId, isSelected) {
      const index = this.selectedVirtualStations.indexOf(stationId);

      if (isSelected && index === -1) {
        this.selectedVirtualStations.push(stationId);
      } else if (!isSelected && index !== -1) {
        this.selectedVirtualStations.splice(index, 1);
      }

      this.selectAll =
        this.selectedVirtualStations.length === this.virtualStations.length;

      if (this.selectAll) {
        this.selectedVirtualStations = this.virtualStations.map(
          station => station.id
        );
      }
    },

    toggleAllProducts() {
      if (this.selectAllProducts) {
        this.selectedProducts = this.filteredAvailableData.map(
          data => data.layerId
        );
      } else {
        this.selectedProducts = [];
      }
    },
    updateSelectedProducts(dataId, isSelected) {
      const index = this.selectedProducts.indexOf(dataId);
      if (isSelected && index === -1) {
        this.selectedProducts.push(dataId);
      } else if (!isSelected && index !== -1) {
        this.selectedProducts.splice(index, 1);
      }

      this.selectAllProducts =
        this.selectedProducts.length === this.filteredAvailableData.length;
      if (this.selectAllProducts) {
        this.selectedProducts = this.filteredAvailableData.map(
          data => data.layerId
        );
      }
    },
    toggleAllDates() {
      if (this.selectAllDates) {
        this.startDateTS = this.firstSceneDate;
        this.endDateTS = this.lastSceneDate;
      } else {
        this.startDateTS = null;
        this.endDateTS = null;
      }
    },
    handleStartDateSelect(date) {
      this.startDateTS = date;
      this.startDateTSCalenderVisible = false;
    },
    handleEndDateSelect(date) {
      this.endDateTS = date;
      this.endDateTSCalenderVisible = false;
    },
    areAllPayloadElementsSet() {
      return (
        this.payloadTimeSeriesPostRequest.rastless_layers.length &&
        this.payloadTimeSeriesPostRequest.virtual_stations.length &&
        this.payloadTimeSeriesPostRequest.start_date &&
        this.payloadTimeSeriesPostRequest.end_date &&
        this.payloadTimeSeriesPostRequest.multiple_files !== null &&
        this.payloadTimeSeriesPostRequest.statistics.length
      );
    },
    toggleSort() {
      this.sortAscending = !this.sortAscending;
    },
    setDefault() {
      this.selectAll = false;
      this.selectedVirtualStations = [];
      this.selectAllProducts = false;
      this.selectedProducts = [];
      this.startDateTS = null;
      this.endDateTS = null;
      this.multipleFiles = false;
      this.selectAllDates = false;
      this.searchQuery = "";
      this.selectedStats = ["median"];
    },
    selectedDatesRange() {
      // console.log(
      //   moment(this.payloadTimeSeriesPostRequest.end_date).diff(
      //     moment(this.payloadTimeSeriesPostRequest.start_date),
      //     "days"
      //   )
      // );
      return moment(this.payloadTimeSeriesPostRequest.end_date).diff(
        moment(this.payloadTimeSeriesPostRequest.start_date),
        "days"
      );
    }
  },
  watch: {
    searchQuery(newVal) {
      if (newVal === null) {
        this.searchQuery = "";
      }
    },
    tabChange() {
      this.setDefault();
    },
    resetTimeSeriesSelection() {
      this.setDefault();
    },

    selectedVirtualStations() {
      this.payloadTimeSeriesPostRequest.virtual_stations = this.selectedVirtualStations;
    },
    selectedProducts() {
      this.payloadTimeSeriesPostRequest.rastless_layers = this.selectedProducts;
    },
    startDateTS(newVal) {
      if (newVal === null) {
        this.selectAllDates = false;
      }
      this.payloadTimeSeriesPostRequest.start_date = newVal;
    },
    endDateTS(newVal) {
      if (newVal === null) {
        this.selectAllDates = false;
      }
      newVal = moment(newVal)
        .add(1, "d")
        .format("YYYY-MM-DD");
      this.payloadTimeSeriesPostRequest.end_date = newVal;
    },
    selectedStats() {
      this.payloadTimeSeriesPostRequest.statistics = this.selectedStats;
    },
    multipleFiles() {
      this.payloadTimeSeriesPostRequest.multiple_files = this.multipleFiles;
    },
    payloadTimeSeriesPostRequest: {
      deep: true,
      handler() {
        this.selectedDatesRange();
        if (
          this.areAllPayloadElementsSet() > 0 &&
          this.selectedDatesRange() >= 0
        ) {
          this.dateMessage = null;
          this.updateProcess(false);
          this.$emit(
            "process-download-for-time-series",
            this.payloadTimeSeriesPostRequest
          );
        } else {
          this.updateProcess(true);
          if (
            this.startDateTS &&
            this.endDateTS &&
            this.selectedDatesRange() < 0
          ) {
            this.dateMessage = this.$t("monitoring.negativeDateRange");
          }
        }
      }
    }
  }
};
</script>
<style scoped>
.ts-border {
  border: 1px solid #d8e1eb;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #e9eff5;
  border-radius: 8px;
}

.label-color {
  color: #173466;
}

.separator-line {
  border-bottom: 1px solid #d8e1eb;
}
>>> .v-messages__message::before {
  content: "⚠";
  color: red;
  margin-right: 5px;
  font-size: 16px;
}

.checkbox {
  white-space: nowrap;
}
</style>
