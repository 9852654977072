<template>
  <v-main>
    <v-container
      fluid
      class="full-height overflow-x-hidden overflow-y-auto no-scrollbar pa-0"
    >
      <!--RETURN-->
      <v-row no no-gutters class="mb-8 mt-5 px-4">
        <v-col class="pa-0">
          <go-back></go-back>
        </v-col>
      </v-row>

      <!--SELECT REGION-->
      <v-row no-gutters class="mb-8 mt-5 ">
        <v-col cols="4" class="pa-0">
          <region-selection-small :showInfo="showInfo"></region-selection-small>
        </v-col>
      </v-row>

      <!--CREATE DOWNLOAD-->
      <create-download
        class="mt-0 mb-8 px-4"
        @tab-changed="selectScenesInfo"
      ></create-download>

      <!--DOWNLOAD RESULTS-->
      <processed-data-results
        :regionChanged="regionChanged"
        class="px-4"
      ></processed-data-results>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import axios from "axios";
import GoBack from "../components/download/GoBack.vue";
import CreateDownload from "../components/download/createDownload/CreateDownload";
import ProcessedDataResults from "../components/download/downloadResults/ProcessedDataResults";
import downloadMixin from "@/core/mixins/download.mixin";
import RegionSelectionSmall from "@/core/components/menu/RegionSelectionSmall";
import RegionMixin from "@/core/mixins/region.mixin";

export default {
  components: {
    GoBack,
    CreateDownload,
    ProcessedDataResults,
    RegionSelectionSmall
  },
  mixins: [downloadMixin, RegionMixin],
  data() {
    return {
      regionChanged: false,
      showInfo: false
    };
  },
  computed: {
    ...mapState("management", ["accessToken"]),
    ...mapState("download", ["layersCollection"]),
    ...mapGetters("management", ["activeRegion", "regionFeatures"]),
    isAdmin() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("admin");
    },
    seeQUT() {
      const client = this.$appConfig.keycloakClient;
      const roles = this.$keycloak.resourceAccess?.[client]?.roles;
      return roles && roles.includes("lfurlp");
    }
  },
  methods: {
    ...mapActions("raster", ["setRasterLayer"]),
    ...mapActions("management", ["getOrFetchRegions", "getOrFetchAccessToken"]),
    ...mapActions("download", [
      "updateSelectedRegion",
      "updateDownloads",
      "updateAvailableData",
      "updateIsLoading",
      "updateFilter",
      "updateRefresh",
      "updateFirstDate",
      "updateLastDate",
      "updateLayersCollection"
    ]),

    async getAllData(regionId) {
      await this.fetchLayers(regionId);

      const response = await axios.get(
        `/userdata/${regionId}/raster-download/`
      );
      const responseTS = await axios.get(
        `/userdata/${regionId}/timeseries-download/`
      );
      const usersDownloads = response.data.map(item => {
        let dateTimeValue;
        if (Array.isArray(item.timesteps) && item.timesteps.length > 1) {
          const firstDateTime = item.timesteps[0];
          const lastDateTime = item.timesteps[item.timesteps.length - 1];

          dateTimeValue = `${firstDateTime.split("T")[0]}, ${
            firstDateTime.split("T")[1]
          }\u00A0\u00A0to\u00A0\u00A0${lastDateTime.split("T")[0]}, ${
            lastDateTime.split("T")[1]
          }`;
        } else if (
          Array.isArray(item.timesteps) &&
          item.timesteps.length === 1
        ) {
          dateTimeValue = `${item.timesteps[0].split("T")[0]}, ${
            item.timesteps[0].split("T")[1]
          }`;
        } else {
          dateTimeValue = "-";
        }
        return {
          createdAt: item.created_at,
          dataType: "Raster data",
          productName: item.product.toUpperCase(),
          dateTime: dateTimeValue,
          fileType: "-",
          status: item.status,
          id: item.id,
          filesData: []
        };
      });

      const layersLookup = Object.assign({}, ...this.layersCollection);

      const userTSDownloads = responseTS.data.map(item => {
        const productNames = item.rastless_layers.map(layerId =>
          layersLookup[layerId]
            ? layersLookup[layerId].product.toUpperCase()
            : null
        );

        const productName = productNames.join(",\u00A0\u00A0");

        const fileType = item.multiple_files
          ? this.$t("downloadView.tsMultipleFiles")
          : this.$t("downloadView.tsAllInOneFile");

        return {
          createdAt: item.created_at,
          dataType: "Time Series data",
          productName: productName,
          dateTime: `${item.start_date}\u00A0\u00A0to\u00A0\u00A0${item.end_date}`,
          fileType: fileType,
          status: item.status,
          id: item.id,
          filesData: item.zip_file
        };
      });
      const combinedDownloads = [...usersDownloads, ...userTSDownloads];

      this.updateDownloads(combinedDownloads);

      await this.fetchTimesteps();
    },
    async fetchLayers(regionId) {
      const client = this.$appConfig.keycloakClient;
      const response = await this.$rastless.get(
        `/layers?client=${client}&region_id=${regionId}&token=${this.accessToken}`
      );

      if (response.data.length > 0) {
        let filteredProducts = null;
        if (!this.isAdmin && !this.seeQUT) {
          filteredProducts = response.data.filter(
            layer => layer.product !== "qut"
          );
        } else {
          filteredProducts = response.data;
        }

        const data = filteredProducts.sort((a, b) =>
          a.title.localeCompare(b.title)
        );

        const layersCollection = data.map(data => ({
          [data.layerId]: {
            product: data.product,
            title: data.title
          }
        }));
        this.updateLayersCollection(layersCollection);

        this.setRasterLayer(data);
        this.itemsLayer = data;
      }
    },
    async fetchTimesteps() {
      const allLayerData = this.itemsLayer.map(item => ({
        id: item.layerId,
        product: item.product,
        title: item.title
      }));

      const requests = allLayerData.map(layerData =>
        this.$rastless
          .get(`/layers/${layerData.id}/steps?token=${this.accessToken}`)
          .then(response => {
            return {
              layerId: layerData.id,
              product: layerData.product,
              title: layerData.title,
              availableScenes: response.data
            };
          })
      );

      const results = await Promise.allSettled(requests);

      const successfulData = results
        .filter(result => result.status === "fulfilled")
        .map(result => result.value);

      await this.updateAvailableData(successfulData);

      const dates = new Set();
      successfulData.forEach(item => {
        item.availableScenes.forEach(scene => {
          dates.add(scene.datetime);
        });
      });
      let sortedDates = Array.from(dates).sort(
        (a, b) => new Date(b) - new Date(a)
      );
      if (sortedDates && sortedDates.length) {
        this.updateLastDate(sortedDates[0].split("T")[0]);
        this.updateFirstDate(sortedDates[sortedDates.length - 1].split("T")[0]);
      } else {
        this.updateLastDate(null);
        this.updateFirstDate(null);
      }

      const errors = results
        .filter(result => result.status === "rejected")
        .map(result => result.reason);

      errors.forEach(error => {
        this.showSnackbar({
          show: true,
          message:
            this.$t("dashboardDetails.fetchingTimestepsErrorMsg") + `${error}`,
          color: "error"
        });
      });
    },

    selectScenesInfo(activeTab) {
      this.showInfo = activeTab === 1;
    },
    async initializeDownloadView() {
      await this.getOrFetchAccessToken();
      await this.fetchVirtualStation(this.activeRegion.id);
      await this.getAllData(this.activeRegion.id);
      this.updateFilter(false);
      this.updateSelectedRegion(this.activeRegion.id);
      this.regionChanged = !this.regionChanged;
    }
  },
  watch: {
    activeRegion: {
      handler(newVal) {
        if (newVal !== undefined) {
          this.initializeDownloadView();
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
<style scoped>
.full-height {
  height: 90vh !important;
}

.no-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.no-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(8, 8, 8, 0.2);
  border-radius: 8px;
}
</style>
