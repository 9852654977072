<template>
  <v-row no-gutters>
    <v-col class="text-right pa-6" style="background-color: #FFFFFF">
      <v-btn
        @click="triggerProcess"
        rounded
        :color="'#039be5'"
        :disabled="this.processBtn"
        >{{ $t("downloadView.processNewDownload") }}</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";
import downloadMixin from "@/core/mixins/download.mixin";

export default {
  props: {
    payloadForRasterDataPostRequest: Array,
    payloadForTimeSeriesPostRequest: Object
  },
  mixins: [downloadMixin],
  data() {
    return {
      isProcessDisabled: true
    };
  },
  computed: {
    ...mapState("download", ["filter", "processBtn"]),
    ...mapGetters("management", ["activeRegion"])
  },

  methods: {
    ...mapActions("download", [
      "updateFilter",
      "updateRefresh",
      "updateProcess"
    ]),
    ...mapActions("app", ["showSnackbar"]),

    triggerProcess() {
      if (this.payloadForRasterDataPostRequest.length > 0) {
        this.processRasterDataForDownload();
      } else if (this.payloadForTimeSeriesPostRequest) {
        this.processTimeSeriesDataForDownload();
      }
    },

    async processRasterDataForDownload() {
      this.updateProcess(true);

      const requests = this.payloadForRasterDataPostRequest.map(data =>
        axios.post(`/userdata/${this.activeRegion.id}/raster-download/`, data)
      );

      const results = await Promise.allSettled(requests);

      const errors = results.filter(result => result.status === "rejected");

      errors.forEach(error => {
        this.showSnackbar({
          show: true,
          message:
            this.$t("downloadView.processNewDownloadErrorMsg") + `${error}`,
          color: "error"
        });
      });
      if (this.filter) {
        this.updateFilter(!this.filter);
        this.$emit("openProductFilter", this.filter);
      }
      await this.getDownloads(this.activeRegion.id);
      this.$emit("download-raster-data-posted");
    },

    async processTimeSeriesDataForDownload() {
      this.updateProcess(true);
      try {
        await axios.post(
          `/userdata/${this.activeRegion.id}/timeseries-download/`,
          this.payloadForTimeSeriesPostRequest
        );
      } catch (error) {
        this.showSnackbar({
          show: true,
          message:
            this.$t("downloadView.processNewDownloadErrorMsg") + `${error}`,
          color: "error"
        });
      }
      await this.getDownloads(this.activeRegion.id);
      this.$emit("download-time-series-posted");
    }
  }
};
</script>
